
import HeaderTit from '@/components/HeaderTit.vue'
import { useRouter, useRoute } from 'vue-router'
import { defineComponent, reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import AxiosAdapter from '@/request/AxiosAdapter'
export default defineComponent({
  components: { HeaderTit },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const code = window.location.search.split('&')[0].split('=')[1]
    //获取跟微信对接的请求参数
    const pay = {
      amount: Number(route.query.amount),
      caseId: route.query.medCaseId,
      caseUserId: Number(route.query.medCaseUserId),
      outTradeNo: route.query.outTradeNo,
      appId: route.query.appid,
      expireTime: '2099-01-01 00:00:00',
      code: code,
      billId: route.query.billId
    }
    //微信方法
    const onBridgeReady = (requestData: object): void => {
      (window as any).WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        requestData,
        (res: any) => {
          if (res.err_msg == 'get_brand_wcpay_request:ok') {
            ElMessage({ type: 'success', message: '支付成功' })
            router.push({
              path: '/paymentsuccess',
              query: { outTradeNo: pay.outTradeNo }
            })
          } else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
            alert('取消支付,请重新扫码支付')
          } else if (res.err_msg == 'get_brand_wcpay_request:fail') {
            alert('支付失败,请联系调解人员')
          }
        }
      )
    }
    //调起微信
    const wechatPay = (result: object): void => {
      //封装请求微信支付接口数据
      const requestData = {
        appId: route.query.appid,
        timeStamp: (result as any).timeStamp,
        nonceStr: (result as any).nonceStr,
        package: (result as any).package,
        signType: 'MD5',
        paySign: (result as any).paySign
      }
      if (typeof (window as any).WeixinJSBridge == 'undefined') {
        if ((document as any).addEventListener) {
          (document as any).addEventListener(
            'WeixinJSBridgeReady',
            onBridgeReady(requestData),
            false
          )
        } else if ((document as any).attachEvent) {
          (document as any)
            .attachEvent(
              'WeixinJSBridgeReady',
              onBridgeReady(requestData)
            )(document as any)
            .attachEvent('OnWeixinJSBridgeReady', onBridgeReady(requestData))
        }
      } else {
        onBridgeReady(requestData)
      }
    }

    //微信请求需要的参数
    const payData = async () => {
      await AxiosAdapter.post({
        url: 'wechatPay/createJSAPIPay',
        data: pay
      }).then((res: any) => {
        wechatPay(res.payer)
      })
    }

    //点击支付
    const goToPay = function () {
      payData()
    }
    return {
      pay,
      payData,
      goToPay,
      wechatPay
    }
  }
})
